import $ from 'jquery';
//import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');

	// Testimonials slider homepage
	function owlCarouselDiensten(){
		jQuery('.subpage-slider').owlCarousel({
		    //center: true,
		    items: 3,
		    margin:10,
		    nav:false,
		    responsive:{
		        0:{
		            items:1
		        },
		        600:{
		            items:2
		        },
		        1000:{
		            items:3
		        }
		    }
		})
	}


	owlCarouselDiensten();
});